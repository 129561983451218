import { AppColors, CustomPalette } from "./brand.model";

// don't export this.
const cbexColors = {
  primaryLight: "#FFC05C",
  primary: "#FF9D00",
  primaryDark: "#E08A00",
  organizationColor: "#0B79D0",
  personColor: "#C77700",
  secondaryLight: "#b085f5",
  secondary: "#4DAF51",
  secondaryDark: "#4d2c91",
  white: "#FFFFFF",
  paleGrey: "rgba(0, 0, 0, 0.6)",
  whitesmoke: "#F9F9F9",
  relationTag: "#C51162",
  primaryLighter: "#f1f7f8",
  error: "#d32f2f",
  contentHover: "rgba(63, 81, 181, 0.08)",
  formGrey: "#f0f0f0",
  alertOrange: "#FFA500",
  black: "#000000",
  fontColour: "#757575",
  fontColourDarkGrey: "#212B31",
  actionableColor: "#729f33",
  timesheetGreen: "rgba(123, 198, 126, 1)",
  timesheetOrange: "rgba(237, 108, 2, 1)",
  dataGridHeader: "#C3D6E2",
  background: "#212B31",
  cbexResult: "#1C6252",
  cbexFail: "#BB482F",
  cbexSuccess: "#4CAF50",
  cbexRequested: "#7DC1AD",
  cbexConfirmed: "#4D907E",
  cbexResultsCollected: "#1C6252",
  outlineVariant: "#f1f7f8",
};

// colors used by all brands
const appColors: AppColors = {
  actionable: cbexColors.actionableColor,
  relationTag: cbexColors.relationTag,
  background: cbexColors.background,
  formControl: cbexColors.formGrey,
  alertOrange: cbexColors.alertOrange,
  disabledBackgroundColor: cbexColors.formGrey,
  timesheetGreen: cbexColors.timesheetGreen,
  cbexResult: cbexColors.cbexResult,
  timesheetOrange: cbexColors.timesheetOrange,
  contentHover: cbexColors.contentHover,
  sidebar: "#FFFAF2",
  error: cbexColors.error,
  blackText: cbexColors.black,
  fontColour: cbexColors.fontColour,
  fontColourDarkGrey: cbexColors.fontColourDarkGrey,
  topBar: cbexColors.white,
  organization: cbexColors.organizationColor,
  person: cbexColors.personColor,
  contentBackground: {
    subtle: cbexColors.white,
    default: cbexColors.whitesmoke,
    primary: cbexColors.primaryLighter,
  },
  dataGridHeader: cbexColors.dataGridHeader,
  cbexFail: cbexColors.cbexFail,
  cbexSuccess: cbexColors.cbexSuccess,
  cbexRequested: cbexColors.cbexRequested,
  cbexConfirmed: cbexColors.cbexConfirmed,
  cbexResultsCollected: cbexColors.cbexResultsCollected,
  outlineVariant: "#FFFAF2",
};

export const colors: CustomPalette = {
  appColors,
  organizationColor: cbexColors.organizationColor,
  personColor: cbexColors.personColor,
  palette: {
    //@ts-ignore
    type: "light",
    background: {
      default: cbexColors.whitesmoke,
      paper: cbexColors.white,
    },
    primary: {
      light: cbexColors.primaryLight,
      main: cbexColors.primary,
      dark: cbexColors.primaryDark,
      contrastText: cbexColors.white,
    },
    secondary: {
      light: cbexColors.secondaryLight,
      main: cbexColors.secondary,
      dark: cbexColors.secondaryDark,
      contrastText: cbexColors.white,
    },
    info: {
      light: cbexColors.secondaryLight,
      main: cbexColors.secondary,
      dark: cbexColors.secondaryDark,
    },
  },
};
